<template>
  <div>
    <Header title="Survey" />
    <v-container style="margin-top: 40px">
      <v-row v-show="isGroupSaved === 0">
        <v-col cols="12" sm="4"></v-col>
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-card-text>
              <h1 class="black--text text-center">Groups</h1>
              <v-divider></v-divider>
              <v-form
                  class="custom_form"
                  ref="form"
                  v-model="validationRules.valid"
                  lazy-validation
              >
                <v-radio-group
                    class="main-group-form-radio"
                    v-model="editedItem.group"
                    row
                    :rules="validationRules.radioRules"

                >
                  <v-radio
                      label="Treatment"
                      value="Treatment"
                  ></v-radio>
                  <v-radio
                      label="Control"
                      value="Control"
                  ></v-radio>
                </v-radio-group>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn ref="g_submit" @click="saveGroup" :disabled="!validationRules.valid" class="btn btn-primary btn-block" dark text>
                Submit
              </v-btn>
            </v-card-actions>
            <v-col cols="12" v-if="checkOldForms()">
              <v-btn ref="o_submit" @click="saveOfflineFormData()" class="btn btn-success btn-block" dark text>Sync Old
                Forms
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <TreatmentForm v-if="editedItem.group === 'Treatment' && isGroupSaved === 1"/>
      <ControlForm v-if="editedItem.group === 'Control' && isGroupSaved === 1"/>
    </v-container>
  </div>
</template>
<script>
import TreatmentForm from "@/view/pages/realstate/survey/TreatmentForm";
import ControlForm from "@/view/pages/realstate/survey/ControlForm";
import {SAVE_BULK_FORMS} from "@/core/services/store/survey.module";
import Swal from "sweetalert2";
import Header from "@/view/pages/realstate/inc/Header";

export default {
  name: 'survey',
  components: {Header, ControlForm, TreatmentForm},
  mounted() {
    if (!this.checkAuth()) {
      this.$router.push({name: "login"});
    }
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
          position => {
            this.applat = position.coords.latitude;
            this.appLong = position.coords.longitude;
          },
          error => {
            console.error("Error getting current location:", error);
          }
      );
    }
  },
  data() {
    return {
      isGroupSaved: 0,
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 50) || "Must be less than or equal to 50 characters"
        ],
        midRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 100) || "Must be less than or equal to 100 characters"
        ],
        degRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 100) || "Must be less than or equal to 100 characters"
        ],
        uniRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 200) || "Must be less than or equal to 200 characters"
        ],
        ageRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than or equal to 1 characters",
          v => (v && v.length <= 3) || "Must be less than or equal to 3 characters"
        ],
        phoneRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 20) || "Must be less than or equal to 20 characters"
        ],
        genderRules: [
          v => !!v || "This field is required"
        ],
        sourceRules: [
          v => !!v || "This field is required"
        ],
        emailRules: [
          v => !!v || "This field  is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        selectRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'This field is required';
            else return true;
          }
        ],
        radioRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'This field is required';
            else return true;
          }
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length <= 20) || "Password must be less than or equal to 20 characters",
          v => (v && v.length >= 6) || "Password must be at least 6 characters"
        ],
      },
      editedItem: {}
    }
  },
  methods: {
    saveGroup() {
      if (this.editedItem.group === 'Treatment' || this.editedItem.group === 'Control') {
        this.isGroupSaved = 1;
      }
    },
    saveOfflineFormData() {
      if (navigator.onLine) {
        const submitButton = this.$refs["o_submit"];
        const gButton = this.$refs["g_submit"];
        submitButton.disabled = true;
        gButton.disabled = true;
        let tItem = JSON.parse(localStorage.getItem("tItem") || "[]");
        let cItem = JSON.parse(localStorage.getItem("cItem") || "[]");
        let data = {
          lat: this.applat,
          lng: this.appLong,
          tItem: tItem,
          cItem: cItem
        }
        this.$store.dispatch(SAVE_BULK_FORMS, {data: data}).then((data) => {
          if (data._metadata.outcomeCode === 8) {
            Swal.fire(data._metadata.message, '', 'error')
          }
          if (data._metadata.outcomeCode === 0) {
            Swal.fire(data._metadata.message, '', 'success')
                .then(() => {
                  localStorage.removeItem('tItem');
                  localStorage.removeItem('cItem');
                }).finally(() => {
                    window.location.reload()
                  });
          }
          submitButton.disabled = false;
          gButton.disabled = false;
        });
      }
    }
  },
};
</script>