<template>
  <v-row>
    <Header title="Control Survey"/>
    <v-col cols="12" sm="12" style="margin-top: 25px">
      <v-form
          ref="form"
          v-model="validationRules.valid"
          lazy-validation
      >
        <v-tabs class="custom-tabs" v-model="activeTab" background-color="#3a4c6e" left dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Section 1</v-tab>
          <v-tab v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">Section 2</v-tab>
          <v-tab v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">Section 3</v-tab>
          <v-tab v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">Section 4</v-tab>
          <v-tab v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">Section 5</v-tab>
          <v-tab v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">Section 6</v-tab>
        </v-tabs>
        <v-tabs-items class="custom-tabs" touchless v-model="activeTab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3>INFORMED CONSENT AND IDENTIFICATION</h3>
                    <p>Introduction:</p>
                    <p>[Greet]</p>
                    <p>Assalam-o- Alaikum:</p>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                        outlined
                        readonly
                        dense v-model="cItem.c_s_1_1"
                        maxLength="100"
                        label="My Name is"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <p>
                      Representing Grant Thornton Anjum Rahman, Chartered Accountants. Our firm is conducting a survey
                      on
                      behalf of PHIMC to assess the overall design and effectiveness of the Sehat Sahulat Program
                      (SSP)-Phase III. Your patience is requested as we go through the questionnaire.
                    </p>
                    <p>The survey aims to evaluate the program's achievement of intended objectives, financial
                      implications, institutional accountability, and transparency of public sector expenditures. This
                      will be carried out through a technical Third-Party Validation in all districts of Punjab
                      operating
                      under the SSP.
                    </p>
                    <p>
                      Rest assured, your name will remain confidential throughout the survey, and the results will be
                      presented in collective tables without individual identifiers. Any information you provide will be
                      kept strictly confidential within our offices and will only be used for the specified purpose.
                    </p>
                    <p>If there's anything unclear during the interview, please feel free to seek clarification. Thank
                      you
                      for your time and participation in this survey.
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">1. Do you have any questions you would like to discuss at this point?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_1_2"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_1_2 || cItem.c_s_1_2 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">2. If yes, what are the questions? </p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_1_3"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">3. May I start the interview?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_1_4"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">4. I have read the informed consent to the primary respondent, and they have
                      confirmed
                      their
                      comprehension of its contents. </p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  
                                  outlined
                                  dense v-model="cItem.c_s_1_5"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                        outlined
                        readonly
                        dense v-model="cItem.c_s_1_6"
                        maxLength="100"
                        label="Name of Interviewer"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_1_7"
                                  maxLength="100"
                                  label=" Interviewer Signature"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                        outlined
                        readonly
                        dense v-model="cItem.c_s_1_8"
                        type="date"
                        label="Date of Interview"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_1_9"
                                  maxLength="100"
                                  label="Name of Reviewer"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_1_10"
                                  maxLength="100"
                                  label="Reviewer’s Signature"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field clearable
                                  outlined
                                  
                                  type="date"
                                  dense v-model="cItem.c_s_1_11"
                                  label="Date of Review"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <p>
                      Thank you for taking the time to participate in this survey. Your feedback plays a vital role in
                      evaluating the effectiveness of the Sehat Sahulat Program's implementation. We encourage you to
                      share honest opinions and experiences to contribute to the ongoing efforts to improve and enhance
                      the program. Your insights are highly valued, and we appreciate your cooperation in this
                      assessment
                      process.</p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goTotab(1)" class="btn btn-primary" dark text>Go to next section &nbsp; <i
                    class="fa fa-angle-right"></i>
                </v-btn>
                <v-btn v-if="cItem.c_s_1_4 === 'No'" :disabled="!validationRules.valid" ref="s_submit"
                       @click="saveControlForm" class="btn btn-theme"
                       dark text>
                  <i class="fa fa-save" style="color: #ffffff"></i> Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3>RESPONDENT PROFILE & DEMOGRAPHIC INFORMATION</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">1. Name of respondent</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_2_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">2. Contact Number</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_2_2"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">3. Patient CNIC Number (without dashes and spaces)</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  @paste="onPaste($event)"
                                  @keypress="isNumber($event)"
                                  @keydown.space="preventLeadingSpace"
                                  
                                  dense v-model="cItem.c_s_2_3"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">District / Tehsil</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-autocomplete clearable
                                    :items="districts"
                                    label="District"
                                    item-value="value"
                                    item-text="value"
                                    outlined
                                    
                                    @change="checkTehsils($event)"
                                    dense v-model="cItem.c_s_2_4"></v-autocomplete>
                    <v-autocomplete clearable
                                    :items="ftehsils"
                                    label="Tehsil"
                                    item-value="value"
                                    item-text="value"
                                    outlined
                                    
                                    dense v-model="cItem.c_s_2_4_1"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">5. Address</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_2_5"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">6 .Do you belong rural or urban area?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_2_6_area"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Rural Area"
                          value="Rural Area"
                      ></v-radio>
                      <v-radio
                          label="Urban Area"
                          value="Urban Area"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">7. Please specify your age (Years) of respondent.</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Years"
                                  dense v-model="cItem.c_s_2_6"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">8. Are you household head?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_2_7"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">9. If No, your relationship with household Head</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_2_8"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">10. Name and Gender of Household Head</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Name"
                                  dense v-model="cItem.c_s_2_9"
                                  maxLength="100"></v-text-field>

                    <v-radio-group
                        
                        v-model="cItem.c_s_2_9_1"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Male"
                          value="Male"
                      ></v-radio>
                      <v-radio
                          label="Female"
                          value="Female"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">11. Age of Household head (years)</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  
                                  outlined
                                  placeholder="Years"
                                  dense v-model="cItem.c_s_2_10"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">12. Education of Household Head (years)</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Years"
                                  dense v-model="cItem.c_s_2_11"
                                  maxLength="100"></v-text-field>
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Degree"
                                  dense v-model="cItem.c_s_2_11_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">13. Occupation of Household head</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items="degrees"
                              item-value="value"
                              item-text="title"
                              outlined
                              
                              dense v-model="cItem.c_s_2_12"></v-select>
                    <v-text-field v-if="cItem.c_s_2_12 === 'Other'" clearable
                                  outlined
                                  
                                  placeholder="[Please specify]"
                                  dense v-model="cItem.c_s_2_12_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">14. Name and Gender of the Patient</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Name"
                                  dense v-model="cItem.c_s_2_13"
                                  maxLength="100"></v-text-field>

                    <v-radio-group
                        
                        v-model="cItem.c_s_2_13_1"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Male"
                          value="Male"
                      ></v-radio>
                      <v-radio
                          label="Female"
                          value="Female"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">15. What is your family size (numbers)</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_2_14"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">16. What is the highest level of Education of any of your family member?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Years"
                                  dense v-model="cItem.c_s_2_15"
                                  maxLength="100"></v-text-field>
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Degree"
                                  dense v-model="cItem.c_s_2_15_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">17. What is your overall approximate household income per month?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="(PKR)"
                                  dense v-model="cItem.c_s_2_16"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">18. What is main source of your family income?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined

                                  dense v-model="cItem.c_s_2_17"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">19. Do you live in your own house or a rented facility?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='[{value: "Own", title: "Own"},{value: "Rented in", title: "Rented in"}]'
                              item-value="value"
                              item-text="title"
                              outlined
                              
                              dense v-model="cItem.c_s_2_18"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">20. Do you have your own car?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_2_19"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                    <v-text-field v-if="cItem.c_s_2_19 === 'Yes'" clearable
                                  outlined
                                  
                                  placeholder="(Brand)"
                                  dense v-model="cItem.c_s_2_19_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">21. Do you have agriculture land?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_2_20"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                    <v-text-field v-if="cItem.c_s_2_20 === 'Yes'" clearable
                                  outlined
                                  
                                  placeholder="(if yes how many acres ______)"
                                  dense v-model="cItem.c_s_2_20_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">22. Do you have urban property?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_2_21"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                    <v-select clearable
                              v-if="cItem.c_s_2_21 === 'Yes'"
                              :items='ulFilter'
                              item-value="value"
                              item-text="title"
                              outlined
                              
                              dense v-model="cItem.c_s_2_21_1"></v-select>
                    <v-text-field v-if="cItem.c_s_2_21_1 === 'Other'" clearable
                                  outlined
                                  
                                  placeholder="Specify Other"
                                  dense v-model="cItem.c_s_2_21_2"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">23. Age of patients(Years)</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Years"
                                  dense v-model="cItem.c_s_2_22"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">24. What disease did you get treated?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Years"
                                  dense v-model="cItem.c_s_2_23"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">25. Could you provide the name of the hospital and the city where you received
                      treatment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Years"
                                  dense v-model="cItem.c_s_2_25"
                                  maxLength="100"></v-text-field>
                    <v-autocomplete clearable
                                    :items="districts"
                                    label="District"
                                    item-value="value"
                                    item-text="value"
                                    outlined
                                    
                                    dense v-model="cItem.c_s_2_25_1"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">26. What was the status of the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='[{value: "Public Hospital", title: "Public Hospital"},{value: "Private Hospital", title: "Private Hospital"}]'
                              item-value="value"
                              item-text="title"
                              outlined
                              
                              dense v-model="cItem.c_s_2_26"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">27. Can you please inform me approximate dates when you got treatment from the said
                      Hospital? (DD-MM-YYYY)</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  type="date"
                                  dense v-model="cItem.c_s_2_27"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">28. Who referred you to this hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items="hospitals"
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_2_28"></v-select>
                    <v-text-field v-if="cItem.c_s_2_28 === 'Any other'" clearable
                                  outlined
                                  
                                  placeholder="[Please Specify]"
                                  dense v-model="cItem.c_s_2_28_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goTotab(2)" class="btn btn-primary" dark text>Go to next section &nbsp; <i
                    class="fa fa-angle-right"></i>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3>PROGRAM AWARENESS</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">1. How did you learn about the healthcare facility you selected?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='lbFilter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              multiple
                              dense v-model="cItem.c_s_3_1"></v-select>
                    <v-text-field clearable
                                  outlined
                                  placeholder="[Please Specify if any other]"
                                  dense v-model="cItem.c_s_3_1_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">2. Are you aware of the services provided?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_3_2"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">3. To your knowledge, what services are provided by the hospital you selected?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='c_s_3_3_filter'
                              item-value="value"
                              item-text="value"
                              multiple
                              outlined
                              
                              dense v-model="cItem.c_s_3_3"></v-select>
                    <v-text-field clearable
                                  outlined
                                  placeholder="[Please Specify if any other]"
                                  dense v-model="cItem.c_s_3_3_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">4. Do you have health insurance?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_3_4"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">5. If yes, what is the name of insurance company?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_3_5"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">6. Do you know about your annual (credit)package limit in case of insured
                      facility?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_3_6"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row
                    v-if="(!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes') && (!cItem.c_s_3_6 || cItem.c_s_3_6 === 'Yes')">
                  <v-col cols="12" sm="8">
                    <p class="m-0">7. What is the annual (credit)package limit on the facility?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Amount in PKR (IPD)"
                                  dense v-model="cItem.c_s_3_7"
                                  maxLength="100"></v-text-field>
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Amount in PKR (OPD)"
                                  dense v-model="cItem.c_s_3_7_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">8. Do you know which types of hospitals can you avail in your health insurance?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='c_s_3_8_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_3_8"></v-select>
                    <v-text-field clearable
                                  v-if="cItem.c_s_3_8 === 'Others'"
                                  outlined
                                  
                                  dense v-model="cItem.c_s_3_8_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">9. Do you know, how many times a year can you use the service?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              
                              :items='c_s_3_9_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              dense v-model="cItem.c_s_3_9"></v-select>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">10. Can you get free consultations and medicines on follow-up visits after discharge
                      from the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='questions'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_3_10"></v-select>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">11. Within how many days after discharge can they access complimentary medications
                      and
                      follow-up consultations?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='c_s_3_11_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_3_11"></v-select>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">12. Do you know about the complaint redressal mechanism of the hospital or insurance
                      company in case of insured patients?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_3_12"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_3_4 || cItem.c_s_3_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">13. How effective do you think the facility's communication strategy is?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='c_s_3_13_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_3_13"></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goTotab(3)" class="btn btn-primary" dark text>Go to next section &nbsp; <i
                    class="fa fa-angle-right"></i>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3>ACCESSIBILITY UTILIZATION OF SERVICES & TREATMENT</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">1. Where have you been traditionally receiving treatment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_4_1_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              multiple
                              dense v-model="cItem.c_s_4_1"></v-select>
                    <v-text-field clearable
                                  outlined
                                  placeholder="[Please Specify if any other]"
                                  dense v-model="cItem.c_s_4_1_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">2. Approximately, how far is the facility located from your home where you received
                      treatment for this disease?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  placeholder="In KM"
                                  outlined
                                  
                                  dense v-model="cItem.c_s_4_2"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">3. How much time did it take to reach the facility?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Hours"
                                  dense v-model="cItem.c_s_4_3"
                                  maxLength="100"></v-text-field>
                    <v-text-field clearable
                                  outlined
                                  @keyup="!cItem.c_s_4_3 ? cItem.c_s_4_3 = '0' : ''"
                                  
                                  placeholder="Minutes"
                                  dense v-model="cItem.c_s_4_3_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">4. Did you have to bear transportation charges to reach the facility?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_4_4"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_4_4 || cItem.c_s_4_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">5. If Yes, how much did you pay to reach the facility?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Amount in PKR"
                                  dense v-model="cItem.c_s_4_5"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">6. Did you face any difficulty for admission in the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_4_6"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_4_6 || cItem.c_s_4_6 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">7. If yes, what challenge you faced please describe</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='c_s_4_7_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_4_7"></v-select>
                    <v-text-field clearable
                                  outlined
                                  
                                  v-if="cItem.c_s_4_7 === 'Any other reason'"
                                  dense v-model="cItem.c_s_4_7_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">8. Did you have to wait for admission due to longer queues / rush at the hospital</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_4_8"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_4_8 || cItem.c_s_4_8 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">9. If yes, for how much time</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Days"
                                  dense v-model="cItem.c_s_4_9"
                                  maxLength="100"></v-text-field>
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Hours"
                                  dense v-model="cItem.c_s_4_9_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goTotab(4)" class="btn btn-primary" dark text>Go to next section &nbsp; <i
                    class="fa fa-angle-right"></i>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3>FINANCIAL IMPACT</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">1. How many days did you spend at hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_5_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">2. Did you re-visit the hospital for the same disease?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_5_2"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_5_2 || cItem.c_s_5_2 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">3. If yes, how many times</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_5_3_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_5_3"></v-select>
                    <v-text-field v-if="cItem.c_s_5_3 === 'Other'" clearable
                                  outlined
                                  
                                  placeholder="(Specify)"
                                  dense v-model="cItem.c_s_5_3_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">4. Did you pay admission fee?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_5_4"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_5_4 || cItem.c_s_5_4 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">5. If yes, how much did you pay?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Rs."
                                  dense v-model="cItem.c_s_5_5"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">6. How much you paid for this treatment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Rs."
                                  dense v-model="cItem.c_s_5_6"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">7. Did you make some medical test from outside the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_5_7"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">8. What was the reason for choosing a medical test facility outside the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              
                              :items='c_s_5_8_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              dense v-model="cItem.c_s_5_8"></v-select>
                    <v-text-field clearable
                                  v-if="cItem.c_s_5_8 === 'Any other reason'"
                                  outlined
                                  
                                  dense v-model="cItem.c_s_5_8_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">9. In your opinion, were you discharged from the hospital in a timely manner?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_5_15_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_5_9"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">10. Did you borrow some money for treatment during hospitalization?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_5_10"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_5_10 || cItem.c_s_5_10 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">11. If yes, then how much did you borrow for this purpose?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  dense v-model="cItem.c_s_5_11"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">12. Did you sell some assets for the treatment purpose?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        v-model="cItem.c_s_5_12"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_5_12 || cItem.c_s_5_12 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">13. If yes what you sold? for how much</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="What you sold"
                                  dense v-model="cItem.c_s_5_13"
                                  maxLength="100"></v-text-field>
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="PKR"
                                  dense v-model="cItem.c_s_5_13_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goTotab(5)" class="btn btn-primary" dark text>Go to next section &nbsp; <i
                    class="fa fa-angle-right"></i>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="!cItem.c_s_1_4 || cItem.c_s_1_4 === 'Yes'">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3>SATISFACTION, FEEDBACK & CHALLENGES</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">1. Are you satisfied with the treatment you or your family received from this
                      hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_1_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_1"></v-select>
                  </v-col>
                </v-row>

                <v-row
                    v-if="!cItem.c_s_6_1 || cItem.c_s_6_1 === '3. Indifferent' || cItem.c_s_6_1 === '4. Unsatisfied' || cItem.c_s_6_1 === '5. Very unsatisfied'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">2. If unsatisfied, What were the primary issues or challenges you faced?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_2_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_2"></v-select>
                    <v-text-field clearable
                                  v-if="cItem.c_s_6_2 === 'Others'"
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_2_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">3. Are you satisfied with the behavior of reception counter / admission staff ?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_3_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_3"></v-select>
                  </v-col>
                </v-row>

                <v-row
                    v-if="!cItem.c_s_6_3 || cItem.c_s_6_3 === '3. Indifferent' || cItem.c_s_6_3 === '4. Unsatisfied' || cItem.c_s_6_3 === '5. Very unsatisfied'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">4.If unsatisfied, What were the primary issues or challenges you faced at the
                      reception counter</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_4_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_4"></v-select>
                    <v-text-field clearable
                                  v-if="cItem.c_s_6_4 === 'Others'"
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_4_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">5. Did you launch a formal complaint on your dissatisfaction to the authorities?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_6_5"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_5 || cItem.c_s_6_5 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">6. Was your complaint resolved?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_7_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_6"></v-select>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_5 || cItem.c_s_6_5 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">7. How long did it take to resolve your complaint?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Days"
                                  dense v-model="cItem.c_s_6_7"
                                  maxLength="100"></v-text-field>
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Hours"
                                  dense v-model="cItem.c_s_6_7_1"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_5 || cItem.c_s_6_5 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">8. What was the complaint?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_8"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">9. Did you receive a call after discharge to get feedback on treatment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_6_9"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_9 || cItem.c_s_6_9 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">10. If yes, which department called you?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  
                                  outlined
                                  dense v-model="cItem.c_s_6_10"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_9 || cItem.c_s_6_9 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">11. If yes, how many days after getting discharged from the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  placeholder="Days"
                                  dense v-model="cItem.c_s_6_11"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">12. Rate the accessibility of services at this hospital in terms of waiting times for
                      appointments.</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='c_s_6_12_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_12"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">13. Rate the effectiveness of the treatments received from this hospital</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_13_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_13"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">14. How would you rate the quality of care received from the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_16_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_14"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">15. Do you agree that the behavior of staff of hospital admission desk was
                      excellent.</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_17_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_15"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">16. How was the behavior of Hospital staff like (Doctors, Nurses and others)?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_16_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_16"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">17. What was the condition of cleanliness at the hospital?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_16_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_17"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>OUTCOMES</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">18. Do you concur that your disease was completely cured after receiving
                      treatment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_17_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_18"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">19. Were you employed before undergoing treatment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_6_19"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">20. Did you start working after treatment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-select clearable
                              :items='t_s_6_22_filter'
                              item-value="value"
                              item-text="value"
                              outlined
                              
                              dense v-model="cItem.c_s_6_20"></v-select>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_20 || cItem.c_s_6_20 !== 'Just like before treatment'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">21. In case of reduced intensity or not working at all, could you please explain the
                      reasons?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_21"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>SUGGESTIONS</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">22. Do you know Sehat Sahulat Program?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_6_22"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_22 || cItem.c_s_6_22 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">23. If yes then how is this program (What is your opinion?)</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_23"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">24. Why did you not use services of this program?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_24"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_22 || cItem.c_s_6_22 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">25. What are your suggestions that how this program can be improved?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_25"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">26. Are you willing to get treatment with co-payment plan of SSP / health card</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_6_26"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">27. What should be the ratio of co-payment?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_27"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="8">
                    <p class="m-0">28. Are you willing to pay for the health card per year?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-radio-group
                        
                        v-model="cItem.c_s_6_28"
                        class="m-0 inner-group-form-radio"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="Yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="!cItem.c_s_6_28 || cItem.c_s_6_28 === 'Yes'">
                  <v-col cols="12" sm="8">
                    <p class="m-0">29. If yes then how much per year?</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="p-0">
                    <v-text-field clearable
                                  outlined
                                  
                                  dense v-model="cItem.c_s_6_29"
                                  maxLength="100"></v-text-field>
                  </v-col>
                </v-row>

                <!--                <v-row>-->
                <!--                  <v-col cols="12" sm="8">-->
                <!--                    <p class="m-0">30. Can you inform me how can the benefits of the program be provided to all?</p>-->
                <!--                  </v-col>-->
                <!--                  <v-col cols="12" sm="4" class="p-0">-->
                <!--                    <v-text-field clearable-->
                <!--                                  outlined-->
                <!--                                  -->
                <!--                                  dense v-model="cItem.c_s_6_30"-->
                <!--                                  maxLength="100"></v-text-field>-->
                <!--                  </v-col>-->
                <!--                </v-row>-->

                <v-row>
                  <v-col cols="12">
                    <p>Thank you for dedicating your time to participate in this survey. Your input is highly valuable
                      for
                      evaluating and enhancing the implementation of the Sehat Sahulat Program.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" class="p-0">
                    <v-textarea clearable
                                outlined
                                
                                label="Comments"
                                dense v-model="cItem.comments"
                                maxLength="500"></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!validationRules.valid" ref="s_submit" @click="saveControlForm" class="btn btn-theme"
                       dark text>
                  <i class="fa fa-save" style="color: #ffffff"></i> Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import {SAVE_CONTROL_FORM} from "@/core/services/store/survey.module";
import Swal from "sweetalert2";
import Header from "@/view/pages/realstate/inc/Header";

export default {
  name: 'control-form',
  components: {Header},
  mounted() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
          position => {
            this.applat = position.coords.latitude;
            this.appLong = position.coords.longitude;
          },
          error => {
            console.error("Error getting current location:", error);
          }
      );
    }
  },
  data() {
    return {
      cItem: {
        c_s_2_3: '',
        lat: '',
        lng: '',
        c_s_4_3: '',
        c_s_1_1: this.getUserName(),
        c_s_1_6: this.getUserName(),
        c_s_1_8: new Date().toISOString().slice(0, 10)
      },
      validationRules: {
        valid: true,
        reqRules: [
          v => !!v || "This field is required."
        ],
        multipleRules: [
          v => {
            if (!v || v.length < 1) return "Select at least one option";
            else return true;
          }
        ]
      },
    }
  },
  methods: {
    saveControlForm() {
      if (this.$refs.form.validate()) {
        this.cItem.user_id = this.getUserId()
        this.cItem.lat = this.applat;
        this.cItem.lng = this.appLong;
        if (navigator.onLine) {
          const submitButton = this.$refs["s_submit"];
          submitButton.disabled = true;
          this.$store.dispatch(SAVE_CONTROL_FORM, {data: this.cItem}).then((data) => {
            if (data._metadata.outcomeCode === 2) {
              Swal.fire('All answers are required.', '', 'error')
            }
            if (data._metadata.outcomeCode === 8) {
              let html = '<div>';
              data.errors.forEach(error => {
                html += `<p>${error}</p>`;
              });
              html += '</div>';
              Swal.fire('', html, 'error')
            }
            if (data._metadata.outcomeCode === 0) {
              Swal.fire(data._metadata.message, '', 'success')
                  .then(() => {
                    window.location.reload()
                  })
            }
            submitButton.disabled = false;
          });
        } else {
          Swal.fire('No internet connection found!', 'Form is saved as draft.', 'warning')
              .then(() => {
                this.saveOfflineCForm(this.cItem);
              }).finally(() => {
            window.location.reload()
          });
        }
      }
    }
  }
}
</script>